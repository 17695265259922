import request from 'utils/request';

const api = {
  CHART_DATA_LIST: '/api/dashboard/monitor/list',
};

export function getChartDataListApi(query) {
  return request({
    url: api.CHART_DATA_LIST,
    method: 'get',
    params: query,
  });
}
