<template>
  <el-dialog :visible.sync="modelVisible" destroy-on-close width="1300px">
    <div v-loading="chartLoading">
      <el-card class="chart-panel__card" shadow="hover">
        <MonitorPanel
          class="chart-panel"
          :x-axis-data="chartData.date"
          :series-data="chartData.value"
          :chart-style="{
            height: '410px',
          }"
          :type="chartTypeConfig[chartData.event]?.type"
          :time="filterCondition.time"
        >
          <template #header>
            <div class="panel-toolbar">
              <div class="panel-title">
                {{ chartTypeConfig[chartData.event]?.name ?? chartData?.event }}
              </div>
              <div class="panel-action">
                <el-select
                  class="panel-action--period"
                  v-model="filterCondition.period"
                  @change="getChartData"
                >
                  <el-option
                    v-for="item in periodOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
                <el-radio-group
                  class="panel-action--time"
                  v-model="filterCondition.time"
                  @change="handleChangeTime"
                >
                  <el-radio-button
                    v-for="item in timeOptions"
                    :label="item.value"
                    :key="item.value"
                    >{{ item.label }}</el-radio-button
                  >
                </el-radio-group>

                <el-date-picker
                  v-if="filterCondition.time === 'custom'"
                  class="panel-action--time"
                  v-model="filterCondition.dateTime"
                  type="datetimerange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  @change="getChartData"
                >
                </el-date-picker>
                <div class="panel-action--refresh" @click="getChartData">
                  <i class="el-icon-refresh"> </i>
                </div>
              </div>
            </div>
          </template>
        </MonitorPanel>
      </el-card>
    </div>
  </el-dialog>
</template>

<script>
  import dayjs from 'dayjs';
  import utc from 'dayjs/plugin/utc';
  import { getChartDataListApi } from '@/api/monitor';

  import { periodOptions, timeOptions, chartTypeConfig } from '../const';
  import MonitorPanel from './MonitorPanel.vue';

  dayjs.extend(utc);
  export default {
    components: {
      MonitorPanel,
    },
    props: {
      visible: {
        type: Boolean,
        default: false,
      },
      type: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        timeOptions,
        periodOptions,
        chartTypeConfig,
        filterCondition: {
          time: '3,hour',
          period: 1,
          dateTime: [new Date(), new Date()],
        },
        chartData: {
          value: [],
          date: [],
          event: '',
        },
        chartLoading: true,
      };
    },
    methods: {
      async getChartData() {
        try {
          this.chartLoading = true;
          const { time, dateTime } = this.filterCondition;

          let fromDate = '',
            toDate = '';
          if (time !== 'custom') {
            const [_time, unit] = time.split(',');
            fromDate = dayjs()
              .utc()
              .subtract(_time, unit)
              .format('YYYY-MM-DD HH:mm:ss');
            toDate = dayjs()
              .utc()
              .format('YYYY-MM-DD HH:mm:ss');
          } else {
            const [startTime, endTime] = dateTime;

            fromDate = dayjs(startTime).format('YYYY-MM-DD HH:mm:ss');
            toDate = dayjs(endTime).format('YYYY-MM-DD HH:mm:ss');
          }

          const { result = [] } = await getChartDataListApi({
            fromDate,
            toDate,
            event: this.type,
            period: this.filterCondition.period,
          });
          this.chartData = result[0] ?? {
            value: [],
            date: [],
          };
        } catch (error) {
          console.error(error);
        } finally {
          this.chartLoading = false;
        }
      },
      handleChangeTime(value) {
        if (value === 'custom') {
          this.filterCondition.dateTime = [new Date(), new Date()];
        }
        this.getChartData();
      },
    },
    computed: {
      modelVisible: {
        get() {
          return this.visible;
        },
        set(_visible) {
          this.$emit('update:visible', _visible);
        },
      },
    },
    watch: {
      visible(_visible) {
        if (!_visible) {
          this.filterCondition = {
            time: '3,hour',
            period: 1,
          };
          return;
        }
        this.getChartData();
      },
    },
  };
</script>
<style lang="scss">
  .wrap {
    padding: 16px;

    .chart-panel {
      .panel-toolbar {
        display: flex;
        justify-content: space-between;
        .panel-action {
          display: flex;
          &--period,
          &--time {
            margin-right: 16px;
          }
          &--period {
            width: 80px;
          }

          &--refresh {
            cursor: pointer;
            display: inline-block;
            padding: 9px 10px;
            border: 1px solid #dcdfe6;
            border-radius: 6px;
            &:hover {
              border-color: #409eff;
              color: #409eff;
            }
          }
        }
      }
      .panel-title {
        font-size: 24px;
        font-weight: bold;
      }
    }
  }
</style>
